module.exports = [{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:400,700"]}},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/buildhome/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
